import React, { useContext, useEffect } from "react"
import { AuthContext } from "../../context/AuthContext"
import { navigate, Link } from "gatsby"

const Header = () => {
  const {
    logout,
    state: { isAuth },
  } = useContext(AuthContext)

  const logoutClicked = () => {
    navigate("/")
    logout()
  }

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <span
          style={{
            textTransform: "uppercase",
            letterSpacing: "6px",
            fontSize: "0.8em",
            fontWeight: 600,
          }}
        >
          <span className="text-primary bg-white p-2">Guardian Counsel</span>
          <span> Law Office</span>
        </span>
      </div>
      <div className="nav navbar-nav navbar-right">
        {isAuth && (
          <>
            <form className="form-inline">
              <Link to="/classroom/manage" className="btn btn-info mx-2">
                Classroom Management
              </Link>
              <button className="btn btn-success my-2 mx-2" onClick={logoutClicked}>
                Logout
              </button>
            </form>
          </>
        )}
      </div>
    </nav>
  )
}

export default Header

import React, { useContext } from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
import ClassroomQuestionsFeed from "../../components/classroom/questionFeed"
import { AuthContext } from "../../context/AuthContext"

const ClassroomIndexPage = () => {
  const { isAuth } = useContext(AuthContext)

  return (
    <>
      <Layout>
        <br />
        <br />

        <h1 style={{ textAlign: "center" }}>Classroom - Dashboard</h1>

        <div style={{ textAlign: "center", marginBottom: "25px" }}>
          {isAuth && (
            <Link className="btn btn-success" to="/classroom/manage">
              Update Class Info
            </Link>
          )}
        </div>

        <h1 style={{ textAlign: "center" }}>Questions</h1>
        <div style={{ flexGrow: 1, border: "1px solid white" }}>
          <ClassroomQuestionsFeed />
        </div>
      </Layout>
    </>
  )
}

export default ClassroomIndexPage

import React, { useEffect, useState } from "react"
import axios from "axios"
import * as signalR from "@microsoft/signalr"

const styles = {
  isAnswered: { backgroundColor: "#4a5e4e", padding: "15px" },
  isNotAnswered: { padding: "15px" },
  question: {
    date: { fontWeight: "bold" },
    name: { fontWeight: "bold", marginLeft: "25px", marginRight: "25px" },
    message: { paddingLeft: "20px", borderLeft: "2px solid white" },
  },
}

const QuestionListItem = ({ question, updateQuestion, deleteQuestion }) => {
  return (
    <>
      <div style={{ padding: "15px" }}>
        <div style={question.isAnswered ? styles.isAnswered : styles.isNotAnswered}>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: "1" }}>
              <span style={styles.question.date}>{question.dateDisplay}</span>
              <span style={styles.question.name}>{question.name}</span>
              <span>sent:</span>
            </div>
            <div>
              <i
                onClick={() => deleteQuestion({ questionId: question.id })}
                onKeyDown={() => deleteQuestion({ questionId: question.id })}
                role="button"
                tabIndex="0"
                className="fa fa-trash"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <div style={styles.question.message}>{question.message}</div>
          <div
            onClick={() => updateQuestion({ questionId: question.id })}
            onKeyDown={() => updateQuestion({ questionId: question.id })}
            role="button"
            tabIndex="0"
            style={{
              cursor: "pointer",
              border: "1px solid white",
              margin: "10px",
              padding: "5px",
              display: "inline-block",
            }}
          >
            {question.isAnswered && (
              <div>
                <i className="fa fa-close" />
                <span style={{ marginLeft: "10px" }}>Mark this as 'not answered'.</span>
              </div>
            )}
            {!question.isAnswered && (
              <div>
                <i className="fa fa-check" />
                <span style={{ marginLeft: "10px" }}>Mark this as 'answered'.</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

const ClassroomQuestionsFeed = () => {
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(true)

  const setQuestionDeleted = ({ questionId }) => {
    setQuestions((qs) => qs.filter((q) => q.id !== questionId))
  }

  const setQuestionAnswered = ({ questionId, isAnswered }) => {
    setQuestions((qs) => qs.map((q) => (q.id === questionId ? { ...q, isAnswered: isAnswered } : q)))
  }

  const deleteQuestionFromList = async ({ questionId }) => {
    await axios.delete(`${process.env.GATSBY_API_URL}/question/${questionId}`)

    setQuestionDeleted({ questionId })
  }

  const updateQuestionFromList = async ({ questionId }) => {
    const oldIsAnswered = questions.find((q) => q.id === questionId).isAnswered
    const isAnswered = !oldIsAnswered

    await axios.post(`${process.env.GATSBY_API_URL}/question/setAnswered`, {
      questionId,
      isAnswered,
    })
    setQuestionAnswered({ questionId, isAnswered })
  }

  const loadQuestions = async () => {
    var results = await axios.get(`${process.env.GATSBY_API_URL}/question/all`)

    setQuestions(results.data)

    setLoading(false)
  }

  const addQuestion = (question) => {
    setQuestions((qs) => [...qs, question])

    var questionsBottom = document.getElementById("questions-bottom")
    questionsBottom.scrollIntoView(true)
  }

  useEffect(() => {
    loadQuestions()

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.GATSBY_API_URL}/hub/questions`)
      .configureLogging(signalR.LogLevel.Information)
      .build()

    connection.on("addQuestion", addQuestion)

    connection.on("updateQuestionAnswered", ({ questionId, isAnswered }) => {
      setQuestionAnswered({ questionId, isAnswered })
    })

    connection.on("deleteQuestion", (questionId) => {
      setQuestionDeleted({ questionId })
    })

    connection.start()
  }, [])

  return (
    <>
      <div>
        {loading && <>Loading...</>}
        {questions.map((q) => (
          <QuestionListItem
            key={q.id}
            question={q}
            updateQuestion={updateQuestionFromList}
            deleteQuestion={deleteQuestionFromList}
          />
        ))}
        <div id="questions-bottom" style={{ visibility: "hidden" }} />
      </div>
    </>
  )
}

export default ClassroomQuestionsFeed
